html {
  scroll-behavior: smooth;
}
.is-justify-content-center {
  justify-content: center;
}

.is-justify-content-space-between {
  justify-content: space-between;
}

.is-align-content-center {
  align-items: center;
}

.is-flex-direction-column {
  flex-direction: column;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap;
}

.is-flex-centered {
  justify-content: center;
  align-items: center;
}

.is-flex-end {
  justify-content: end;
}

.full-width {
  width: 100vw;
}

.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.25em;
}
.m-2 {
  margin: 0.5em;
}
.m-3 {
  margin: 0.75em;
}
.m-4 {
  margin: 1em;
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25em;
}
.mt-2 {
  margin-top: 0.5em;
}
.mt-3 {
  margin-top: 0.75em;
}
.mt-4 {
  margin-top: 1em;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25em;
}
.mb-2 {
  margin-bottom: 0.5em;
}
.mb-3 {
  margin-bottom: 0.75em;
}
.mb-4 {
  margin-bottom: 1em;
}

.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.25em;
}
.ml-2 {
  margin-left: 0.5em;
}
.ml-3 {
  margin-left: 0.75em;
}
.ml-4 {
  margin-left: 1em;
}

.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.25em;
}
.mr-2 {
  margin-right: 0.5em;
}
.mr-3 {
  margin-right: 0.75em;
}
.mr-4 {
  margin-right: 1em;
}

.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25em;
}
.p-2 {
  padding: 0.5em;
}
.p-3 {
  padding: 0.75em;
}
.p-4 {
  padding: 1em;
}

.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.25em;
}
.pt-2 {
  padding-top: 0.5em;
}
.pt-3 {
  padding-top: 0.75em;
}
.pt-4 {
  padding-top: 1em;
}

.pr-4 {
  padding-right: 1em;
}

.pl-4 {
  padding-left: 1em;
}

/* bulma modifiers */

.file {
  justify-content: center !important;
}

/* others */
.flag-icon {
  display: flex;
  justify-content: center;
  font-size: 5em;
}

.color_red {
  color: red;
}

/* maps modifiers */
.map {
  height: 60vh;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  display: block;
}

.no-frame-input {
  background-color: transparent;
  border: 0px solid;
  height: 1em;
  width: 100%;
  color: #ccc;
  margin: 0.75em 0.1em;
  padding: 0.75em 0.25em;
  cursor: pointer;
}

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  border: solid 1px #107964;
  background: #0a5546;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background: #22dab5;
  margin-top: -5px;
  border-radius: 50%;
}

.slider {
  width: 90%;
}

/* body::-webkit-scrollbar,
.content::-webkit-scrollbar,
.menu::-webkit-scrollbar {
  display: none;
} */

body,
.footer {
  background: #c9d6ff;
  background: -webkit-linear-gradient(to right, #e2e2e2, #c9d6ff);
  background: linear-gradient(to right, #e2e2e2, #c9d6ff);
}

body,
.content,
.menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.clock {
  position: absolute;
  top: 1em;
  left: 1em;
  text-justify: center;
  text-align: center;
  vertical-align: middle;
}

.menu {
  max-height: 60vh;
  overflow: scroll;
  position: relative;
  top: 6em;
  padding-bottom: 4em;
}

.content {
  max-height: 100vh;
  overflow: scroll;
}

.has-background-primary-light {
  background-color: hsl(171, 100%, 96%);
}
.has-background-link-light {
  background-color: hsl(219, 70%, 96%);
}
.has-background-info-light {
  background-color: hsl(206, 70%, 96%);
}
.has-background-success-light {
  background-color: hsl(142, 52%, 96%);
}
.has-background-warning-light {
  background-color: hsl(48, 100%, 96%);
}
.has-background-danger-light {
  background-color: hsl(347, 90%, 96%);
}

.has-text-success-dark {
  color: hsl(141, 53%, 31%);
}
.has-text-warning-dark {
  color: hsl(48, 100%, 29%);
}
.has-text-danger-dark {
  color: hsl(348, 86%, 43%);
}

.pointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.contain_login {
  min-height: 80vh;
}

.contain {
  min-height: 72vh;
}

.login {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.7);
}

.transparency {
  background-color: rgba(255, 255, 255, 0.7);
}

.remove-img {
  position: relative;
  bottom: 1em;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
